<template>
  <div>
    <b-overlay
      :show="isLoading || isCheckout"
      rounded="sm"
    >
      <form-wizard
        :title="null"
        :subtitle="null"
        :back-button-text="$t('previous')"
        :next-button-text="$t('next')"
        @on-complete="onSubmit"
      >
        <!-- Order Details tab -->

        <tab-content
          :title="$t('delivery_details')"
          icon="feather icon-file-text"
          :before-change="validateFirstSteps"
        >
          <ValidationObserver
            ref="deliveryDetails"
            v-slot="{ passes }"
          >
            <form @submit.prevent="passes(validateFirstSteps)">
              <b-row class="mb-2">
                <b-col cols="12">
                  <b-row class="mb-1">
                    <b-col
                      sm="6"
                      md="5"
                      lg="6"
                    >
                      <DeliveryType
                        :id="'self-delivery-option'"
                        :value="1"
                        :title="$t('self_delivery')"
                        :description="$t('self_delivery_description')"
                        :form="orderForm"
                        @setPickUpAddresses="handleSetPickupAddresses"
                      />
                    </b-col>
                    <b-col
                      sm="6"
                      md="5"
                      offset-md="2"
                      lg="6"
                      offset-lg="0"
                      class="d-flex"
                    >
                      <DeliveryType
                        :id="'smarttruck-delivery-option'"
                        :value="0"
                        :title="$t('smarttruck_delivery')"
                        :description="$t('smarttruck_delivery_description')"
                        :form="orderForm"
                        @setPickUpAddresses="handleSetPickupAddresses"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <PickUpAddressAndDeliveryDate
                :form="orderForm"
                :pick-up-addresses="pickUpAddresses"
                @setPickupId="handleUpdateForm"
              />
              <hr>
              <TypeOfMarketAndClient
                :markets="markets"
                :form="orderForm"
                :recipients="recipients"
                :outlets="outlets"
                :type-of-order="'single order'"
                @setRecipients="handleSetRecipients"
                @setOutlets="handleSetOutlets"
                @setDropoffId="handleUpdateForm"
              />
            </form>
          </ValidationObserver>
        </tab-content>
        <tab-content
          :title="$t('product_details')"
          icon="feather icon-package"
          :before-change="validateSecondSteps"
        >
          <ValidationObserver
            ref="productDetails"
            v-slot="{ passes }"
          >
            <form @submit.prevent="passes(validateFirstSteps)">
              <ProductDetails
                :order="orderForm"
                :categories="categories"
                :packaging="packaging"
                :remarks="remarks"
              />
            </form>
          </ValidationObserver>
        </tab-content>
        <tab-content
          :title="$t('checkout')"
          icon="feather icon-shopping-cart"
        >
          <CheckoutOrder
            :order="orderForm"
            :items="itemsTable"
            :charges="charges"
            :is-fetching-price="isFetchingPrice"
            :has-price-set="hasPriceSet"
            :vouchers="vouchers"
          />

        </tab-content>
        <template
          v-if="filteredForm.price > 0"
          slot="custom-buttons-right"
        >
          <b-button
            class="wizard-btn"
            variant="info"
            @click.prevent="addToCart"
          >
             <b-spinner
              v-if="isCheckout"
              class="mr-50"
              small
            />
            Add to Cart
          </b-button>
        </template>
        <tab-content
          :title="'Payment Method'"
          icon="feather icon-dollar-sign"
        >
          <div>
            <PaymentMethod @payment_method="handleSetPaymentMethod" />
          </div>
        </tab-content>
        <b-button
          slot="finish"
          class="wizard-btn"
          variant="primary"
          :disabled="isCheckout || !hasPriceSet"
        >
          <b-spinner
            v-if="isCheckout"
            class="mr-50"
            small
          />
          {{ $t('proceed_to_payment') }}
        </b-button>

      </form-wizard>
      <template #overlay>
        <div class="text-center">
          <b-spinner
            medium
            variant="primary"
          />
          <p
            id="cancel-label"
            class="text-primary"
          >
            {{ loadingMessage }}
          </p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationObserver } from 'vee-validate'
import {
  CheckoutOrder,
  ProductDetails,
  TypeOfMarketAndClient,
  PickUpAddressAndDeliveryDate,
  DeliveryType,
} from '@/components/CreateOrderFields'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { mapGetters } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { PaymentMethod } from '@/components'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    CheckoutOrder,
    ProductDetails,
    TypeOfMarketAndClient,
    PickUpAddressAndDeliveryDate,
    ValidationObserver,
    DeliveryType,
    PaymentMethod,
  },
  data() {
    return {
      mop: '',
    }
  },
  computed: {
    ...mapGetters({
      orderForm: 'singleDelivery/orderForm',
      itemsTable: 'singleDelivery/itemsTable',
      hasPriceSet: 'singleDelivery/hasPriceSet',
      isFetchingPrice: 'singleDelivery/isFetchingPrice',
      packaging: 'singleDelivery/packaging',
      categories: 'singleDelivery/categories',
      remarks: 'singleDelivery/remarks',
      isLoading: 'singleDelivery/isFetchingFields',
      markets: 'singleDelivery/markets',
      recipients: 'singleDelivery/recipients',
      outlets: 'singleDelivery/outlets',
      pickUpAddresses: 'singleDelivery/pickUpAddresses',
      charges: 'singleDelivery/charges',
      isCheckout: 'orders/isLoading',
      vouchers: 'orders/vouchers',
      filteredForm: 'singleDelivery/filteredForm',
    }),
    loadingMessage() {
      if (this.isLoading) {
        return 'Fetching fields data...'
      }

      if (this.isCheckout) {
        return 'Processing Order...'
      }
      return ''
    },
  },
  created() {
    this.$store.dispatch('singleDelivery/fetchFieldsValue')
  },
  methods: {
    async addToCart() {
      const response = await this.$store.dispatch('orders/storeOrder', {
        orderData: this.filteredForm,
        step: 3,
      })
      if (response.status === 422) {
        this.$refs.deliveryDetails.setErrors(response.data.errors)
        this.$refs.productDetails.setErrors(response.data.errors)
        this.$refs.maxQuantityCheck.setErrors(response.data.errors)
      }
      if (response.status === 200) {
        window.location.href = '/my-cart'
      }
    },
    async onSubmit() {
      const response = await this.$store.dispatch('orders/storeOrder', {
        orderData: this.filteredForm,
        step: 3,
      })
      const { order_id } = response.data.data
      const tempPay = response.data.data.process_payment.url
      if (response.status === 422) {
        this.$refs.deliveryDetails.setErrors(response.data.errors)
        this.$refs.productDetails.setErrors(response.data.errors)
        this.$refs.maxQuantityCheck.setErrors(response.data.errors)
      }
      if (response.status === 200) {
        if (this.mop === 'SENANGPAY') {
          const mop = await this.$http.post('order/update_payment_method', {
            order_id,
            payment_method: this.mop,
          })
          window.location.href = tempPay
        } else {
          await this.$http.post('order/update_payment_method', {
            order_id,
            payment_method: this.mop,
          })
          const processLogpoint = await this.$http.post(
            'logpoint/process_order',
            { order_id, total_amount: this.filteredForm.price },
          )
          if (processLogpoint.data.status) {
            window.location.href = `/delivery-confirmed/${processLogpoint.data.data.reference_number}/${this.mop}`
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Not Enough Logpoint. Please reload or choose other payment methods.',
                icon: 'WarningIcon',
                variant: 'warning',
              },
            })
            this.isLoading = false
          }
        }
      }
    },
    async validateFirstSteps() {
      const response = await this.$store.dispatch('orders/storeOrder', {
        orderData: this.filteredForm,
        step: 1,
      })
      if (response.status === 422) {
        this.$refs.deliveryDetails.setErrors(response.data.errors)
        if (this.filteredForm.pickup_id && this.filteredForm.dropoff_id) {
          if (
            response.data.errors.sender_address
            || response.data.errors.outlet_address
          ) {
            this.$swal({
              title: 'Contact Customer Service',
              text: 'Oops! Price is not set for these states, kindly proceed to contact with our Customer Service representative at this hotline 019-8196692. Thank you!',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#ea5455',
            })
          }
        }
      }
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          resolve(true)
        }
        reject()
      })
    },
    async validateSecondSteps() {
      const response = await this.$store.dispatch('orders/storeOrder', {
        orderData: this.filteredForm,
        step: 2,
      })
      if (response.status === 422) {
        this.$refs.productDetails.setErrors(response.data.errors)
      }
      return new Promise((resolve, reject) => {
        if (response.status === 200) {
          if (
            this.filteredForm.is_over_size
            || this.filteredForm.is_over_weight
          ) {
            return this.doubleChargeConfirmation().then(confirmed => {
              if (confirmed) {
                this.$store.dispatch('orders/fetchVouchers')
                this.$store.dispatch('singleDelivery/fetchCharges')
                resolve(true)
              }
              reject()
            })
          }
          this.$store.dispatch('orders/fetchVouchers')
          this.$store.dispatch('singleDelivery/fetchCharges')
          resolve(true)
        }
        reject()
        return false
      })
    },
    handleSetPaymentMethod(value) {
      this.mop = value
    },
    doubleChargeConfirmation() {
      return new Promise(resolve => {
        let message = 'size/weight'
        if (this.filteredForm.is_over_size) {
          message = 'size'
        }
        if (this.filteredForm.is_over_weight) {
          message = 'weight'
        }

        this.$swal({
          title: 'Double Charged',
          text: `You will be double charged due to "${message}" limit exceeded! Are you sure want to continue?`,
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            resolve(true)
          }
          resolve(false)
        })
      })
    },
    handleSetPickupAddresses(value) {
      this.handleUpdateForm({ is_pickup: value })
      this.$store.dispatch('singleDelivery/resetSenderAddress')
      this.$store.dispatch('singleDelivery/setPickUpAddresses')
    },
    handleSetRecipients() {
      this.$store.dispatch('singleDelivery/setRecipients')
    },
    handleSetOutlets() {
      this.handleUpdateForm({
        client_id: this.orderForm.recipient.id,
      })
      this.$store.dispatch('singleDelivery/setOutlets')
    },
    handleUpdateForm(data) {
      this.$store.dispatch('singleDelivery/updateOrderForm', { data })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
